@import '@app/core/variables.scss';

.wizard-view-container {
  &__sidebar {
    display: none;

    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;

    border-radius: 8px 0 0 8px;

    &-link {
      display: flex;
      flex-direction: row;
      padding-bottom: 13px;
      margin-left: 12.5px;
      border-left: 2px solid $color-gray-100-borders;

      &-icon-container {
        width: 25px;
        height: 25px;
        margin-right: 10px;
        margin-left: -12.5px; // half of hexagon width, shows a continuous line through all hexagons

        > img {
          width: 100%;
        }
      }

      &-title {
        flex: 1;
        display: none;
        align-items: center;
        font-weight: $standard-extra-heavy-weight;
      }
    }

    &-link:last-child {
      padding-bottom: 0; // do not extend the connecting lines beyond the last hexagon
    }
  }

  @media only screen and (min-width: 601px) {
    &__sidebar {
      display: block;
      width: 80px;
      padding: 80px 32px 0 32px;
    }
  }

  @media only screen and (min-width: 961px) {
    &__sidebar {
      width: 240px;
      padding: 80px 26px 0 26px;

      &-link {
        &-title {
          display: flex;
        }
      }
    }
  }
}
