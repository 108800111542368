.common-component__chart {
  &-container {
    height: 350px;
    position: relative;
  }

  &-inner-container {
    height: 100%;
  }

  &-header-title {
    position: absolute;
  }

  &-header-tooltip {
    position: absolute;
    right: -24px;
    bottom: 8px;
    z-index: 6;
  }
}
