@import '@app/core/variables.scss';

// this is here because it prevents scroll-to-top behavior on click away or click of menu item
body {
  height: auto;
}

.atom-menu__menu {
  li {
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 150px;
  }

  &-item {
    &.Mui-disabled {
      color: $color-gray-200-disabled-state;
    }
  }
}
