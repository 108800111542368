@import '@app/core/variables.scss';

.image-card {
  aspect-ratio: 421 / 207;
  background-repeat: no-repeat;
  background-size: cover;

  color: $color-white;
  border-radius: 4px;

  .gradient-bg-layer {
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    border-radius: 4px;
  }

  .text-overlay-layer {
    margin: 24px 16px;
    display: flex;
    align-items: center;

    font-size: 17px;
    font-weight: 700;

    svg {
      margin-right: 16px;
    }
  }
}
