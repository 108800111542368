@import '@app/core/variables.scss';

.game-progression-room__menu-item-container {
  display: flex;
  align-items: center;

  .menu-item {
    margin-right: 8px;
    fill: $color-gray-300-icons;
  }
}
