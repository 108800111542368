.section-header__container {
  display: flex;
  flex-direction: row;
  padding-left: 24px;

  .section-header__title {
    margin: 5px 0px 5px 10px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #4a4a4a;
  }
}

.section-header__blue-vertical-bar {
  width: 2px;
  background: #70a9fa;
}
