@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

@mixin no-transition {
  transition: border 0ms, margin 0ms, padding 0ms;
}

.atom-component__single-select-button-border {
  border: 1px solid $color-gray-100-borders;
  box-shadow: 0 1px 4px 0 $color-gray-100-borders;

  &:hover {
    background-color: $color-white;
    box-shadow: 0 3px 5px 0 $color-gray-300-icons;
  }

  &:disabled {
    border: none;
  }

  &.selected {
    border: 2px solid $color-blue-hover-dark;
    background-color: $color-blue-hover-light;
    box-shadow: 0 1px 4px 0 $color-gray-100-borders;
    @include no-transition;
  }
}

.atom-component__single-select-button-styles {
  height: 40px;
  background-color: $color-white;
  padding: 0 16px;
  font-size: 14px;
  color: $color-gray-900-primary-text;
  font-weight: $standard-regular-weight;
  text-transform: none; //remove the material UI upper case
  margin-bottom: 12px;
  @include no-transition;

  &.large-format {
    @include normal-input-text;
    font-weight: $standard-medium-weight;
    height: 160px;
    width: 160px;
    margin-bottom: 24px;
    padding: 0 8px;
  }

  &:disabled {
    background-color: $color-gray-25-content-background;
    color: $color-gray-200-disabled-state;
  }
}

.atom-component__single-select-button-align-items {
  @include flex-center-align;
  flex-direction: row;

  &.large-format {
    margin: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (min-width: 600px) {
  .atom-component__single-select-button-styles {
    margin-bottom: 0;

    &.large-format {
      margin-bottom: 0;
    }
  }
}
