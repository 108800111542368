.no-access-tooltip {
  padding: 8px;

  &__test,
  &__url {
    font-size: 14px;
  }

  &__url {
    display: flex;
    justify-content: end;
  }
}
