@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.overview-prize-enablement__path-icon-container {
  .icon {
    height: 18px;
    width: 18px;

    &-gift {
      height: 42px;
      width: 42px;
    }

    &-game {
      height: 32px;
      width: 32px;
      border-radius: 4px;

      &-default {
        height: 32px;
        width: 32px;
        border-radius: 4px;
        fill: $color-gray-300-icons;
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .overview-prize-enablement__path-icon-container {
    .icon {
      height: 20px;
      width: 20px;
    }
  }
}
