@mixin h1-spacing-default {
  margin-top: 24px;
  margin-bottom: 16px;
}

@mixin h2-spacing-default {
  margin-top: 16px;
  margin-bottom: 16px;
}

@mixin h3-spacing-default {
  margin-bottom: 8px;
}

@mixin p-default {
  margin-bottom: 16px;
  line-height: 21px;
}

@mixin h1-spacing-page-title {
  margin-top: 24px;
  margin-bottom: 24px;
}

@mixin standard-margin-bottom {
  margin-bottom: 12px;
}

@mixin section-padding-bottom {
  padding-bottom: 32px;
}
