@mixin subtext-size-height {
  font-size: 14px;
  line-height: 19px;
}

@mixin subtext {
  @include subtext-size-height;
  color: $color-gray-500-secondary-text;
  font-weight: $standard-medium-weight;
}

@mixin normal-input-text {
  font-size: 16px;
  line-height: 21px;
}

@mixin h2-default-style {
  font-size: 32px;
  line-height: 36px;
  font-weight: $standard-medium-weight;
}

@mixin h3-default-style {
  font-size: 20px;
  line-height: 27px;
  font-weight: $standard-medium-weight;
}

@mixin h4-default-style {
  font-size: 16px;
  line-height: 21px;
  font-weight: $standard-medium-weight;
}

@mixin h4-regular-weight-style {
  font-size: 16px;
  line-height: 24px;
  font-weight: $standard-regular-weight;
}

@mixin p3-secondary-style {
  font-size: 12px;
  line-height: 16px;
  color: $color-gray-500-secondary-text;
}

@mixin default-text-color {
  color: $color-gray-900-primary-text;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin ellipsis-with-width {
  @include subtext-size-height;
  @include ellipsis;
  width: var(--maxwidth);
}
