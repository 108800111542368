@import '@app/core/atoms.scss';
@import '@app/core/variables.scss';

.atom-component__table-row {
  .atom-component__table-row-cell {
    font-family: Roboto;
    font-size: 16px;
    padding-left: 0px;
    padding-top: 24px;
    padding-bottom: 16px;

    &.enabled {
      color: $color-gray-900-primary-text;
    }

    &.disabled {
      color: $color-gray-200-disabled-state;
    }
  }
}
