@import './elevations.scss';

@mixin flex-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-left-align {
  display: flex;
  align-items: center;
  justify-content: left;
}

//Subtext
@mixin subtext {
  font-size: 14px;
  line-height: 19px;
  color: $color-gray-500-secondary-text;
  font-weight: $standard-medium-weight;
}

@mixin normal-input-text {
  font-size: 16px;
  line-height: 21px;
}

//Spacing
//type elements are not aligned with the current DSM (off by 1)
@mixin h1-spacing-default {
  margin-top: 24px;
  margin-bottom: 16px;
}

@mixin h2-spacing-default {
  margin-top: 16px;
  margin-bottom: 16px;
}

@mixin h3-default {
  margin-bottom: 8px;
}

@mixin p-default {
  margin-bottom: 16px;
  line-height: 21px;
}

@mixin h1-spacing-page-title {
  margin-top: 24px;
  margin-bottom: 24px;
}

// image
@mixin undraggable-image {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

// Link
@mixin link-remove-text-decoration {
  @at-root {
    a#{&},
    a:link#{&},
    a:visited#{&},
    a:hover#{&},
    a:active#{&},
    a:focus#{&} {
      text-decoration: none;
    }
  }
}

//Page
@mixin page-outer-container {
  padding: 24px;
}

@mixin standard-margin-bottom {
  margin-bottom: 12px;
}

@mixin section-padding-bottom {
  padding-bottom: 32px;
}

@mixin modal-container-mobile-format {
  max-height: 92%;
  overflow-y: auto;
}

@mixin header-box-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.16) !important;
}

@mixin h2-default-style {
  font-size: 32px;
  line-height: 36px;
  font-weight: $standard-medium-weight;
}

@mixin h3-default-style {
  font-size: 20px;
  line-height: 27px;
  font-weight: $standard-medium-weight;
}

@mixin h4-default-style {
  font-size: 16px;
  line-height: 21px;
  font-weight: $standard-medium-weight;
}

@mixin purple-blue-gradient {
  background-image: linear-gradient(to left top, $color-progress-bar-purple, $color-brand-blue);
}

// override material ui - used for grids
@mixin width-100-margin-0 {
  margin: 0;
  width: 100%;
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
