@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.mobile-view-container {
  .archer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .step-oval {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      border: 2px solid white;
      background-color: $color-gray-200-disabled-state;

      &.complete {
        background-color: $color-green;
      }
    }
  }

  .step-container {
    display: flex;
    flex-direction: row;

    .step-icon {
      padding-right: 8px;
    }

    .step-copy > p,
    .step-copy > div > p {
      font-size: 14px;
      font-weight: $standard-medium-weight;

      &.pending {
        color: $color-gray-200-disabled-state;
      }
    }

    .step-copy > div > p {
      &.pending {
        color: $color-gray-200-disabled-state;
      }
    }

    .step-copy > div {
      display: flex;
      flex-direction: row;
      cursor: pointer;

      > img {
        width: 14px;
        height: 14px;
        padding-bottom: 4px;
        object-fit: contain;
      }
    }
  }
}

// help split the checklist into two columns
@media screen and (min-width: 456px) {
  .mobile-view-container {
    .steps-container {
      height: 90px !important;
    }
  }
}
