@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.atom-component__button-with-loader {
  @include flex-center-align;

  &__loader {
    @include flex-center-align;
    position: absolute;
  }

  // background button for loader
  > button.atom-component__button {
    visibility: hidden;
  }
}
