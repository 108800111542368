@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.promotion-banner {
  &__container {
    height: 74px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    &__ufc {
      background: $color-ufc-red;
    }
    &__nfl {
      background-image: linear-gradient(
        267deg,
        $color-nfl-blue-dark,
        $color-nfl-blue-medium,
        $color-nfl-blue-light
      );
    }
  }
  &__body {
    margin: 0 8px;
    width: 100%;
    max-width: 100%;
    &__header {
      flex-basis: 100%;
    }
  }

  &__content {
    @include normal-input-text;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    color: $color-nfl-white-text;
    padding: 0;
  }

  &__left-content {
    display: flex;
    align-items: center;
  }

  &__banner-image {
    &__ufc {
      width: 120px;
      height: 42px;
    }
    &__nfl {
      width: 42px;
      height: 58px;
    }
  }

  &__left-text-content {
    padding-left: 16px;
    min-height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: normal;
  }

  &__banner-header {
    &__ufc {
      font-weight: $standard-medium-weight;
      &::after {
        content: 'UFC Branded Mobile Games';
      }
      font-size: 16px;
    }
    &__nfl {
      font-weight: $standard-medium-weight;
      &::after {
        content: 'NFL Developer Challenge';
        font-size: 18px;
      }
    }
    &:only-child {
      font-size: 18px;
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  &__right-content {
    flex-shrink: 0;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .promotion-banner__navigation-link,
    .promotion-banner__navigation-link:focus {
      font-weight: $standard-medium-weight;
      text-decoration: none;
      color: $color-nfl-white-text;
    }
  }

  &__close-icon {
    margin-left: 5px;
  }

  &__minimized-container {
    max-width: 400px;
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 16px;
    border-radius: 4px;
  }
}

.signup-promotion-banner {
  position: relative;
  width: 100%;
  max-width: 472px;
  display: flex;
  background: $color-white;
  border-radius: 32px;
  border: 2px solid transparent;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    z-index: -1;
    border-radius: inherit;
    background: linear-gradient(to bottom, #0043ff 15%, #ea0449 85%);
  }

  &__content {
    display: flex;
    padding: 13px 21px;
    border-radius: 30px;
    border: 2px solid transparent;
    background: $color-white;

    img {
      height: 123px;
      width: 123px;
      margin-right: 23px;
    }
  }

  &__left-text-content {
    min-height: 31px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: normal;
  }

  &__banner {
    &-top-description {
      font-size: 19px;
      font-weight: 400;
      line-height: 26px;
      font-family: 'area-normal', Roboto, sans-serif;
    }
    &-description {
      font-size: 15px;
      font-weight: 1000;
      line-height: 23px;
      font-family: 'area-normal', Roboto, sans-serif;
      color: #ea0449;
      text-transform: uppercase;
      margin-top: 15px;
    }
  }
}

@media screen and (min-width: 600px) {
  .promotion-banner {
    &__container {
      margin-top: 0;
    }

    &__content {
      padding-right: 5px;
    }

    &__body {
      &__header {
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    &__close-icon {
      margin-left: 30px;
    }

    &__banner-header {
      &__ufc {
        &::after {
          content: 'UFC Branded Competitive Mobile Games';
        }
      }

      &__nfl {
        &::after {
          content: 'NFL Game Developer Challenge';
        }
      }
    }
  }
}

@media screen and (min-width: 460px) {
  .promotion-banner__banner-header {
    &__ufc {
      &::after {
        content: 'UFC Branded Competitive Mobile Games';
      }
    }

    &__nfl {
      &::after {
        content: 'NFL Game Developer Challenge';
      }
    }
  }
}
