$box-shadow-black-hue12: rgba(0, 0, 0, 0.12);
$box-shadow-black-hue14: rgba(0, 0, 0, 0.14);
$box-shadow-black-hue20: rgba(0, 0, 0, 0.2);

// to be added to the container level of hover effect
@mixin elevation-transition {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

@mixin elevation-level-1 {
  box-shadow: 0 1px 1px 0 $box-shadow-black-hue14, 0 2px 1px -1px $box-shadow-black-hue12,
    0 1px 3px 0 $box-shadow-black-hue20;
}

@mixin elevation-level-2 {
  box-shadow: 0 2px 2px 0 $box-shadow-black-hue14, 0 3px 1px -2px $box-shadow-black-hue12,
    0 1px 5px 0 $box-shadow-black-hue20;
}

@mixin elevation-level-3 {
  box-shadow: 0 3px 4px 0 $box-shadow-black-hue14, 0 3px 3px -2px $box-shadow-black-hue12,
    0 1px 8px 0 $box-shadow-black-hue20;
}

@mixin elevation-level-4 {
  box-shadow: 0 4px 5px 0 $box-shadow-black-hue14, 0 1px 10px 0 $box-shadow-black-hue12,
    0 2px 4px -1px $box-shadow-black-hue20;
}

@mixin elevation-level-6 {
  box-shadow: 0 6px 10px 0 $box-shadow-black-hue14, 0 1px 18px 0 $box-shadow-black-hue12,
    0 3px 5px -1px $box-shadow-black-hue20;
}

@mixin elevation-level-8 {
  box-shadow: 0 8px 10px 1px $box-shadow-black-hue14, 0 3px 14px 2px $box-shadow-black-hue12,
    0 5px 5px -3px $box-shadow-black-hue20;
}

@mixin elevation-level-9 {
  box-shadow: 0 9px 12px 1px $box-shadow-black-hue14, 0 3px 16px 2px $box-shadow-black-hue12,
    0 5px 6px -3px $box-shadow-black-hue20;
}

@mixin elevation-level-12 {
  box-shadow: 0 12px 17px 2px $box-shadow-black-hue14, 0 5px 22px 4px $box-shadow-black-hue12,
    0 7px 8px -4px $box-shadow-black-hue20;
}

@mixin elevation-level-16 {
  box-shadow: 0 16px 24px 2px $box-shadow-black-hue14, 0 6px 30px 5px $box-shadow-black-hue12,
    0 8px 10px -5px $box-shadow-black-hue20;
}

@mixin elevation-level-24 {
  box-shadow: 0 24px 38px 3px $box-shadow-black-hue14, 0 9px 46px 8px rgba(0, 0, 0, 0.12),
    0 11px 15px -7px $box-shadow-black-hue20;
}

@mixin elevation-disabled {
  box-shadow: 0 1px 3px 0 rgba(184, 189, 199, 0.16), 0 2px 2px 0 rgba(184, 189, 199, 0.16),
    0 0 2px 0 rgba(184, 189, 199, 0.16);
}
