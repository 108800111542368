@mixin page-outer-container {
  padding: 24px;
}

@mixin header-box-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.16) !important;
}

@mixin modal-container-mobile-format {
  max-height: 92%;
  overflow-y: auto;
}
