@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

.profile-widget {
  &__container {
    @include elevation-level-1;
    border-radius: 3px;
    padding: 32px;
    width: 100%;
    height: 100%;

    &:hover {
      @include elevation-level-8;
      cursor: pointer;
    }
  }

  &__profile-icon {
    @include flex-center-align;
    border-radius: 64px;
    background: linear-gradient(to bottom right, rgba(72, 144, 232, 0.8), rgba(204, 72, 232, 0.8));
    width: 32px;
    height: 32px;
    margin-right: 8px;

    & &-initials {
      color: $color-white;
      text-align: center;
      font-weight: $standard-regular-weight; // same as first-last-name
      margin: auto; // to see margins being even around initial
    }
  }

  &__container &__studio-name {
    @include ellipsis;
    color: $color-gray-500-secondary-text;
    padding-bottom: 16px;
  }

  &__icon-name-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 27px; // 32px to the top of the progress bar, but 27px to top of Edit button
    height: 50%;
  }

  &__container &__first-last-name {
    @include ellipsis;
    max-width: calc(100% - 40px);
    font-weight: $standard-regular-weight;
    color: $color-gray-500-secondary-text;

    &.user-name-italics {
      font-style: italic;
      overflow: visible; // override for overflow: hidden since italics make the last letter slightly cut off
    }
  }

  &__default-profile-icon {
    padding-right: 8px;
  }

  &__progress-bar-edit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2px; // 8px from progress bar to % complete, but 2 from edit button to % complete text
    // override material ui atom progress bar
    > .MuiLinearProgress-root {
      max-width: calc(66.66%);
      display: inline-block;
      margin-top: 0;
    }

    > .atom-component__link-button {
      margin-top: 0;
      display: inline-block;
    }
  }

  &__percent-complete-text {
    @include subtext;
    font-weight: $standard-regular-weight;
  }
}
