@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.wizard-view-container {
  position: fixed;
  inset: 30px;
  background-color: $color-white;
  z-index: 1000001; // 2 higher than drift widget chat bubble
  border-radius: 8px;

  @include elevation-level-24;

  display: flex;

  &__main-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .wizard-view-container__content-box {
      flex: 1;
      display: flex;
      flex-direction: column;
      max-height: calc(100% - 32px); // header height
    }
  }

  @media only screen and (min-width: 601px) {
    // vertically center
    margin-top: -340px; // half of container height
    inset: 50% 10% auto;
    height: 680px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
}
