@import '@app/core/complete-reset.scss';
@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

.banner {
  &__container {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 4px;
    padding: 16px;

    .atom-component__colored-circle {
      margin: 0;
    }

    &.warn {
      background-color: $color-warn-background;
      border: 1px solid $color-orange-100;
    }

    &.info {
      background-color: $color-info-background;
      border: 1px solid $color-info-border;

      .atom-component__colored-circle {
        background-color: $color-info-icon;
      }
    }

    &.error {
      background-color: $color-red-background;
      border: 1px solid $color-red-border;

      .atom-component__colored-circle {
        background-color: $color-red;
      }
    }
  }

  &__content {
    @include p-default;
    font-weight: $standard-regular-weight;
    margin: 2px 0 0 16px; // fix 2px top for first line to be vertically center aligned with 24px icon
    display: inline-block;
  }

  // warn icon
  &__icon-triangle {
    text-align: center;
    align-content: center;
    display: flex;
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 24px solid $color-warn-icon;
      position: absolute;
    }

    &-span {
      margin: auto;
      margin-top: 7px;
      color: $color-white;
      z-index: 1;
    }
  }
}
