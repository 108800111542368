@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

@mixin basic-icon {
  width: 67px;
  height: 67px;
  border-radius: 16px;
  margin-right: 16px;
}

.game-object-card {
  &__container {
    @include elevation-level-1;
    @include elevation-transition;
    display: flex;
    justify-content: space-between;
    background-color: $color-white;
    padding: 16px;
    border-radius: 4px;
    cursor: pointer;
    height: 99px;
    position: relative;

    &:not(.disabled) {
      &:hover {
        @include elevation-level-8;
      }

      &.raised {
        @include elevation-level-8;
      }
    }

    &.disabled {
      opacity: 0.5;

      .game-object-card__link {
        cursor: default;
      }
    }

    .active {
      background-color: $color-gray-25-content-background;
    }
  }

  &__container-archived {
    @extend .game-object-card__container;
    cursor: auto;
    &:hover {
      @include elevation-level-1;
    }

    &.raised {
      @include elevation-level-1;
    }

    .active {
      background-color: $color-gray-25-content-background;
    }
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__content-section {
    @include flex-center-align;
    max-width: calc(100% - 48px); // 48px is the width of the kabob with padding

    &-text {
      @include flex-left-align;
      // 83px = 67px (width of icon) + 16px (margin right)
      max-width: calc(100% - 83px);
      align-items: start;
      flex-direction: column;
    }
  }

  &__icon {
    @include basic-icon;
    flex-shrink: 0;
  }

  &__blank-container {
    @include basic-icon;
    @include flex-center-align;
    background-color: $color-white;
    border: solid 1px $color-gray-100-borders;

    .game-object-card__blank-container-icon {
      height: 44px;
      width: 52px;
      fill: $color-gray-100-borders;
    }
  }

  &__game-name {
    @include ellipsis;
    @include normal-input-text;
    max-width: 100%; // needed for ellipsis
  }

  &__updated-at {
    @include subtext;
    font-weight: $standard-regular-weight;
    margin-bottom: 8px;
  }

  &__menu-section {
    @include flex-center-align;
    height: 40px;
    width: 40px;
    position: relative;
    left: 8px;
    bottom: 8px;
    cursor: pointer;

    &:hover {
      background-color: $color-gray-25-content-background;
    }
  }
}
