@import '@app/core/complete-reset.scss';
@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

.common-component__back-button-container {
  display: flex;
  align-items: center;
  margin-top: -8px; //should tally up to 16px when back button is rendered
  margin-bottom: 24px;

  &:hover {
    cursor: pointer;
  }
  .icon {
    color: $color-brand-blue;
    height: 12px;
    width: 7px;
  }

  .text {
    @include normal-input-text;
    color: $color-brand-blue;
    margin-bottom: 0;
    margin-left: 4px;
  }
}
