@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.atom-component__colored-circle {
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  align-content: center;
  display: flex;
  background-color: $color-gray-500-secondary-text;
  flex-shrink: 0;

  &-span {
    margin: auto;
    color: $color-white;
  }
}
