@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

#notifications__modal-container {
  @include modal-style;
  min-height: 300px;
  display: flex;

  .atom-component__contents {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    overflow-y: auto;

    h2 {
      @include h2-default-style;
    }

    h3 {
      @include h3-default-style;
    }

    p {
      @include normal-input-text;
    }
  }

  .atom-component__close-button {
    z-index: 1;
    padding: 8px;
    height: 40px; // 24px + 8px * 2 >> icon height + vertical padding
    transition: none;

    &:hover {
      border-radius: 0 4px;
    }
  }

  #notifications__modal-later {
    color: $color-gray-600-secondary-text;
    font-size: 12px;
    line-height: 16px;
    margin-top: 0px;
  }
}

.notifications {
  &__modal-header {
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    padding: 24px;
    background: $color-white;
    h2 {
      max-width: calc(100% - 26px); // 14px + 12px - close icon + left-padding
    }

    span {
      @include subtext;
      font-weight: $standard-regular-weight;
    }

    &-close-icon {
      position: absolute;
      top: 0;
      right: 0;
    }

    &.with-shadow {
      @include elevation-level-1;
      transition: box-shadow 0.5s ease-in-out;
    }
  }

  &__modal-body {
    overflow-y: auto;
    top: -3px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    h3 {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    > p {
      padding-bottom: 16px;

      > img {
        width: 80%;
        margin: 8px 10%;
      }
    }

    strong {
      font-weight: $standard-medium-weight;
    }

    em {
      font-style: italic;
    }

    &-action {
      @include flex-center-align;
      margin-top: 16px;
      margin-bottom: 24px; // 40px - 16px margin bottom of button
      flex-wrap: wrap;

      .notifications__modal-button {
        &-primary {
          margin: 0 12px 16px 12px;
        }

        &-secondary {
          margin: 0 12px 16px 12px;
        }
      }

      .break {
        flex-basis: 100%;
        height: 0px;
      }
    }

    ul,
    ol {
      margin-left: 40px;
      padding-bottom: 16px;

      li {
        @include normal-input-text;
      }
    }

    #lottie {
      max-width: 40%;
    }
  }

  &__modal-footer {
    background-color: $color-gray-50-table-header-site-bkgd;
    padding: 16px 24px;
    width: 100%;
    color: $color-gray-500-secondary-text;
    font-size: 14px;
    position: absolute;
    bottom: 0;

    a span {
      font-size: 14px;
    }
  }
}

@media screen and (min-width: 600px) {
  .notifications {
    &__modal-body {
      > p > img {
        width: 60%;
        margin: 8px 20%;
      }
    }
  }
}
