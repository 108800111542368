@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

.card-with-icon-and-link {
  &__container {
    @include elevation-level-1;
    @include flex-center-align;
    flex-direction: column;
    padding: 16px 8px;
    border-radius: 4px;
  }

  &__icon {
    width: 80px;
    height: auto;
    margin-bottom: 16px;
    flex-shrink: 0;
  }

  &__text {
    @include p-default;
    @include flex-center-align;
    flex-direction: column;
    text-align: center;
  }
}
