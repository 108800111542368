@import '@app/core/variables.scss';

.atom-component__radio-button {
  color: $color-gray-300-icons !important;
  &-disabled {
    color: $color-gray-200-disabled-state !important;
  }
  &-selected {
    color: $color-brand-blue !important;
    &-disabled {
      color: $color-gray-200-disabled-state !important;
    }
  }
}
