@import '@app/core/variables.scss';

.fuzzy-finder__section {
  display: flex;

  &.left {
    justify-content: flex-end;
  }

  &.right {
    justify-content: flex-start;
  }

  .atom-component__text-field-container {
    &.animated {
      margin: 0;
      width: 50px;
      transition: width 0.4s ease-in-out;
    }

    &.animated:focus-within {
      margin: 0;
      width: 100%;
    }

    &.expand {
      margin: 0;
      width: 100%;
    }
  }
}

.fuzzy-finder__search-icon {
  margin-top: 3px;
  cursor: pointer;

  &.left {
    margin-right: 2px;
  }

  &.right {
    margin-left: 2px;
  }

  svg {
    fill: $color-gray-300-icons;
  }
}
