.common-component__tabs-container {
  margin-bottom: 8px;
  box-shadow: none !important;
}

.common-component__tabs-cell {
  span {
    font-size: 16px;
    line-height: 19px;
    text-transform: none;
  }
}

.common-component__tabs-content {
  height: 100%;
}
