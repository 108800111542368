// TODO comment this back in when we are ready to change all the React pages
// @import 'complete-reset';

// @import 'atoms';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

@font-face {
  font-family: "area-normal";
  src: url("https://use.typekit.net/af/0cd949/0000000000000000774b9fc1/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"), url("https://use.typekit.net/af/0cd949/0000000000000000774b9fc1/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"), url("https://use.typekit.net/af/0cd949/0000000000000000774b9fc1/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 100;
  font-stretch: normal
}

@font-face {
  font-family: "area-normal";
  src: url("https://use.typekit.net/af/911b4f/0000000000000000774b9fbd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/911b4f/0000000000000000774b9fbd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/911b4f/0000000000000000774b9fbd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal
}

@font-face {
  font-family: "area-normal";
  src: url("https://use.typekit.net/af/6f9a29/0000000000000000774b9fc8/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/6f9a29/0000000000000000774b9fc8/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/6f9a29/0000000000000000774b9fc8/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
  font-stretch: normal
}

@font-face {
  font-family: "area-normal";
  src: url("https://use.typekit.net/af/363d17/0000000000000000774b9fbf/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/363d17/0000000000000000774b9fbf/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/363d17/0000000000000000774b9fbf/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal
}

@font-face {
  font-family: "area-normal";
  src: url("https://use.typekit.net/af/ce56d9/0000000000000000774b9fcb/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/ce56d9/0000000000000000774b9fcb/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/ce56d9/0000000000000000774b9fcb/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
  font-stretch: normal
}

.new-container {
  background-color: $color-white;
  width: 100%;
  padding: 0;

  .game-management-game-header__outer-container {
    width: 100% !important;
  }

  .game-management-integration__container {
    margin-top: unset;
  }

  // log-out
  .home-page__introduction-container {
    margin-top: 0 !important;
  }

  .home-page__how-works-paid-container {
    background-color: #edeff2;
  }
}

//preload icons in the game info modal off screen
.app__main-container {
  overflow-y: visible;

  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1; // hide images
    content: url('../../imageAssets/apple.svg') url('../../imageAssets/androidClassicGreenLogo.svg')
      url('../../imageAssets/cordovaLogo.svg') url('../../imageAssets/androidStudioLogo.svg')
      url('../../imageAssets/unityLogo.png') url('../../imageAssets/xCodeLogo.png');
  }

  // if the container or the immediate div after is empty (component not rendered yet) show a loader
  &:empty,
  > div:first-child:empty {
    position: absolute;
    top: 33%;
    left: calc(50% - 2em);
    width: 4em;
    height: 4em;
    border: 0.7em solid rgba(0, 0, 0, 0.1);
    border-left: 0.7em solid #808080;
    border-radius: 50%;
    animation: loading 1.1s infinite linear; // keyframe defined in gdc.scss
  }
}

@media screen and (min-width: 900px) {
  .app__main-container {
    &:empty,
    > div:first-child:empty {
      left: calc(50% - 2em + 97px); // add half the width of navbar
    }
  }
}

.MuiAutocomplete {
  &-root {
    color: $color-gray-900-primary-text;

    .MuiChip {
      &-root {
        border-radius: 4px;
        background-color: $color-gray-50-table-header-site-bkgd;
      }
      &-label {
        color: $color-gray-900-primary-text;
      }
    }
    .MuiAvatar-root {
      color: #fafafa; // mui overriding
      border-radius: 4px;
    }

    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }

  // when the selection tool pops down
  &-popper {
    .MuiAvatar-root {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      margin-right: 4px;
    }

    span {
      @include normal-input-text;
      color: $color-gray-900-primary-text;
    }
  }
}

// TODO [Remove] once snackbar components is defined
.MuiSnackbarContent {
  &-root {
    background-color: $color-white;
    color: $color-gray-900-primary-text;
  }

  &-message {
    color: $color-gray-900-primary-text;
  }
}

// TODO [Remove] once modal (dialog) components is defined
.MuiDialogTitle {
  &-root {
    padding: 24px 32px 17px 32px;
  }
}

.MuiDialogActions {
  &-root {
    padding: 16px 32px;
  }
}

.MuiDialogContent {
  &-root {
    padding: 32px;
  }
}

// wrapped in parent to avoid !important
.MuiDialog-container {
  .MuiDialogContent {
    &-root {
      padding-top: 32px;
    }
  }
}
