.password {
  &-image {
    width: 16px;
  }
  &-textfield {
    &__icon {
      &.button {
        padding: 8px;
      }
    }

    &__position-end {
      margin-right: -14px; // offset +14px from MuiOutlinedAdornedEnd
    }
  }
}
