@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.atom-component__container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $color-white;
  border-radius: 3px;
  border-color: $color-gray-100-borders;
  padding: 4px;

  &:focus {
    outline: none;
  }

  .atom-component__close-button {
    position: absolute;
    right: 0;
  }

  @media screen and (max-width: 600px) {
    width: 90%;
  }
}

.atom-component__contents {
  @include flex-center-align;
}
