.password-text-field {
  &__checklist {
    margin-top: 8px;
  }

  &__checklist-item-padding {
    padding-bottom: 8px;

    &.last {
      padding: 0;
    }
  }
}
