@import '@app/core/variables.scss';

// override material UI
@mixin meta-tag-line-and-height {
  line-height: 19px !important;
  height: 20px !important;
}

.atom-component__meta-tag-styles {
  @include meta-tag-line-and-height;
  font-size: 14px !important;
  border-radius: 12px;
  margin-right: 8px;
  background-color: $color-gray-50-table-header-site-bkgd !important;
  color: $color-gray-500-secondary-text !important; //override material UI class

  span {
    @include meta-tag-line-and-height;
  }
}

.atom-component__meta-tag-text {
  @include meta-tag-line-and-height;
  font-weight: normal;
  font-size: 14px;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.atom-component__meta-tag-text:hover {
  cursor: text;
}
