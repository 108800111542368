@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

.atom-component__interactive-icon {
  @include flex-center-align;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 2px;

  &:hover,
  &.active {
    background-color: $color-gray-25-content-background;
  }

  &.corner {
    // first value: top-left & bottom right
    // second value: top-right & bottom left
    border-radius: 0 4px;
    padding: 8px;

    > button {
      transition: none;
      height: 100%;
      width: 100%;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
