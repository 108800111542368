@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.modal__header {
  h2 {
    @include h2-default-style;
    text-align: center;
    margin-bottom: 24px;
  }
}

.modal__subheader {
  h4 {
    @include h4-default-style;
    text-align: left;
    margin-bottom: 16px;
  }
}
