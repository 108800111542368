@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

.page-header {
  &__outer-container {
    @include header-box-shadow;
    background-color: $color-gray-25-content-background;
  }

  &__text {
    padding: 12px;
  }
}
