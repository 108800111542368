@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.atom-component__link-button {
  @include normal-input-text;
  font-weight: $standard-medium-weight;
  color: $color-brand-blue;
  margin-bottom: 0;
  margin-top: 16px;

  &:hover {
    cursor: pointer;
    color: $color-blue-hover-dark;
  }
}
