@import '../../styles/core/variables.scss';
@import '../../styles/mixins.scss';

.atom-component__text-field-container {
  margin: 0;

  legend > span {
    font-size: 1em; // this has to be done because our atoms.scss standard span font-size was overwriting the outlined field label size
  }

  &.no-label {
    legend > span {
      display: none;
    }
  }

  &.MuiFormControl-marginDense {
    input {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
      height: 1.1876em;
    }

    .MuiInputLabel-outlined {
      // .MuiInputLabel-marginDense {
      transform: translate(14px, 12px) scale(1);
      height: 1.1876em;
      line-height: 1.1876em;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}
