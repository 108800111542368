@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.atom-component__snackbar {
  &-success {
    &-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;

      h4 {
        @include h4-default-style;
      }
    }

    &-icon {
      margin-right: 8px;
    }
  }

  &-icon {
    &.close {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  &-content {
    &-message > div:first-child {
      margin-right: 24px; // width of close X
    }

    &.mui-override {
      padding: 10px 16px;
    }
  }

  .atom-component__button {
    margin-right: 36px; // offset taking into account for button
  }
}
