@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.atom-component__date-selector {
  &-container {
    margin: 0px !important;
  }

  &__text-field-container {
    @include textfield-override-no-label;
    @include textfield-override-dense;
    @include textfield-override-dense-outlined-transition;
  }
}
