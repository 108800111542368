@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
// import amboy-black from adobe for use in prize enablement success modal
@import url('https://use.typekit.net/fqa3cvz.css');
@import './variables.scss';

body {
  font-family: Roboto;
  color: $color-gray-900-primary-text;
  font-weight: $standard-regular-weight;
}

//Body
div,
span,
body,
p,
h3 {
  font-size: 16px;
}

p {
  line-height: 1.31;
}

//Headers - Page Title
h1 {
  font-size: 32px;
  line-height: 36px;
  font-weight: $standard-medium-weight;
}

//Headers - Section
h2 {
  font-size: 20px;
  line-height: 27px;
  font-weight: $standard-medium-weight;
}

//Headers - Content
h3 {
  font-weight: $standard-medium-weight;
  line-height: 1.31;
}

//Decorations & Labels
.decorations-and-labels {
  font-size: 12px;
  line-height: 16px;
}

//Navigation
nav {
  font-size: 16px;
  line-height: 21px;
  color: $color-white;
  font-weight: $standard-medium-weight;
}

.key-value {
  font-size: 52px;
  line-height: 56px;
  font-weight: $standard-medium-weight;
}
