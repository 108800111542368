@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.atom-component__image-uploader-container {
  cursor: pointer;
  position: relative;

  .atom-component__image-uploader-preview {
    object-fit: fill;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    border-radius: 4px;
    position: relative;
  }

  .atom-component__image-uploader-default-icon-container {
    padding: 24px 24px 8px 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0;
    left: 0;
    z-index: 0;
    border: 1px solid $color-gray-100-borders;
    border-radius: 4px;
    background: $color-white;
    position: absolute;

    .atom-component__image-uploader-upload-icon {
      width: 100%;
      height: 70%;
      opacity: 0.2;
      fill: $color-gray-300-icons;
      border-radius: 4px;
    }

    .atom-component__image-uploader-upload-icon-label {
      font-size: 16px;
      line-height: 21px;
      color: $color-brand-blue;
      text-align: center;
      margin-bottom: 8px;
    }
  }

  &:hover {
    .atom-component__image-uploader-default-icon-container {
      background: rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      z-index: 100;

      .atom-component__image-uploader-upload-icon {
        opacity: 1;
        fill: $color-white;
      }

      .atom-component__image-uploader-upload-icon-label {
        color: $color-white;
      }
    }
  }
}
