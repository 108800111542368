@import '../../styles/atoms.scss';
@import '../../styles/mixins.scss';

.atom-component__button {
  white-space: nowrap;

  &.primary {
    @include elevation-level-1;

    &:hover {
      @include elevation-level-3;
    }

    &:disabled {
      @include elevation-disabled;
    }
  }
}
