@import '@app/core/complete-reset.scss';
@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

:root {
  /* declare custom variables here */
  --main-text-color: #4a4a4a;
  --grey-text-color: #979797;
}

#root {
  background: none;
}

.sign-up-pages__background {
  //54px is the nav bar
  padding: 41px 18px 0 18px;
  position: relative; //position relative for the abs position of the gem and knight icon
  overflow: hidden;
  min-height: 100vh;

  > :nth-child(2) {
    position: relative;
  }
}

.flex-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.flex-row-container div {
  &.forgot-password-text {
    margin-top: -1px;
  }
}

#redesign-user-flow {
  font-family: 'Roboto';
  color: var(--main-text-color);
  margin-top: 38px;
  height: 100%;
  width: 100%;
}

.change-password {
  &__container {
    text-align: center;

    h2 {
      margin-bottom: 18px;
    }
  }

  &__input-field {
    &.first {
      margin-bottom: 18px;
    }

    &.second {
      margin-bottom: 24px;
    }
  }
}

.sign-up {
  &__container {
    h2 {
      margin-bottom: 12px;
    }
  }
}

.forgot-pass {
  &__container {
    h2 {
      margin-bottom: 14px;
    }
  }
}

#sign-in-remember-me {
  &-label {
    font-size: 10px;
  }

  &-checkbox ~ svg {
    width: 14px;
    height: auto;
  }
}

#sign-up-marketing-opted-in {
  &-label {
    font-size: 9px;
    line-height: 12px;
  }

  &-checkbox ~ svg {
    width: 14px;
    height: auto;
  }
}

a.sign-up-flow__link {
  color: $color-new-purple;
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:visited {
    color: $color-new-purple;
    text-decoration: none;
    cursor: pointer;
  }
}

a,
a:hover,
a:visited {
  color: #70a9fa;
  text-decoration: none;
  cursor: pointer;
}

.subtext {
  font-size: 12px;
  color: var(--grey-text-color);
  padding-top: 30px;
}

.subtext.dark {
  color: #4a4a4a;
  font-size: 12px;
}

.forgot-password-text {
  font-size: 10px;
  text-align: right;
}

.material-item {
  padding: 8px 0;
}

.material-password a {
  color: var(--grey-text-color);
  font-size: 9px;
}

.material-password input {
  height: 15px;
}

.checkbox label {
  padding-left: 0px;
}

.sign-up-checkbox {
  margin-top: 18px;
  text-align: left;

  &.landing-page {
    max-width: calc(333px - 48px);
  }
}

#fake-password {
  display: none;
}

@media screen and (max-width: 414px) {
  #root {
    padding: 0;
  }

  .material-item {
    &.flex-row-container {
      padding: 10px 0;
    }
  }

  .sign-up-checkbox {
    padding: 5px;
    padding-top: 10px;
  }
}

#sign-up-confirm-password {
  position: absolute;
  top: -500px;
  left: -500px;
}

#sign-in-password-helper-text,
#sign-in-email-helper-text {
  width: 96%;
}

/* css below is for platform logos under the card container on landing page */
.landing-page-platform-logos {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  margin-top: -30px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px 0 24px 0;
}

#sign-up-pulse-loader,
#sign-in-pulse-loader {
  width: 100%;
  height: 100%;
}

.sign-up-pulse-loader-container,
.sign-in-pulse-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 100%;
}

.home-page__welcome-signup-container-overhaul {
  box-shadow: none !important;
  border-radius: 17px;
  font-family: 'area-normal', sans-serif;
  padding: 16px 26px !important;

  h2 {
    margin: 0 auto 46px auto;
    font-family: inherit;
    font-weight: 600;
    font-size: 17px;
    color: #000;
  }

  .sign-up-flow__button button {
    background: #3e00cd;
    border-radius: 9px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 3;
    font-size: 9px;
    box-shadow: none !important;
  }
}

.landing-page-platform-logos-overhaul {
  margin-top: 0;
  padding: 10px 0 0 0;
  font-family: 'area-normal', sans-serif;
}

//new design styles
.sign-up-background__container {
  background-color: #000;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  background-image: url('@app/imageAssets/homePage/Skillz_longer_bg.svg');
  background-repeat: no-repeat;
  background-position: 0 275px;
  background-size: auto 60%;
}

.sign-in-background__container {
  background-color: #000;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;

  &::before {
    content: '';
    background: linear-gradient(139deg, #0043ff 2.83%, #ea0449 49.15%);
    height: 775px;
    width: calc(100% + 96px);
    padding: 0 -48px;
    border-bottom-left-radius: 85% 35%;
    border-bottom-right-radius: 85% 35%;
  }
}
.sign-up-flow {
  &__container {
    background: $color-white;
    border-radius: 36px;
    padding: 53px 60px;
    margin-bottom: 15px;
    font-family: 'area-normal', Roboto, sans-serif;
    color: #000;
    margin-top: 200px;
    z-index: 5;

    h2 {
      text-align: center;
      font-size: 37px;
      font-weight: 1000;
      margin-bottom: 20px;
      line-height: 37px;
    }

    h4 {
      text-align: center;
      font-weight: 400;
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 24px;
    }
  }

  &__subheader {
    margin-bottom: 20px;
  }

  &__input-container {
    margin-bottom: 20px;
    font-size: 15px;
    label.MuiFormLabel-filled,
    label.Mui-focused {
      margin: 0;
    }
  }

  &__button {
    margin-top: 20px;
    button {
      background: $color-new-purple;
      width: 100%;
      text-transform: uppercase;
      font-family: 'area-normal', Roboto, sans-serif;
      font-size: 19px;
      font-weight: 1000;
      line-height: 22px;
      border-radius: 15px;
      box-shadow: none;
      padding: 19px 12px;
      height: 61px;
      letter-spacing: 2.6px;
      border: 1px solid $color-new-purple;
      &:hover {
        background-color: $color-white;
        color: $color-new-purple;
      }
    }
  }

  &__already-have-account-container {
    text-align: center;
    margin-top: 20px;
    font-family: 'area-normal', Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
  }

  &__bottom-subtext {
    font-size: 11px;
    font-family: 'area-normal', Roboto, sans-serif;
    font-weight: 400;
    margin-top: 12px;
    text-align: center;
  }
}
.homepage-flow {
  &__input-container {
    margin-bottom: 20px;
    font-size: 15px;
  }

  &__button {
    margin-top: 20px;
    button {
      background: $color-new-purple;
      width: 100%;
      text-transform: uppercase;
      font-family: 'area-normal', Roboto, sans-serif;
      font-size: 14px;
      font-weight: 1000;
      line-height: 22px;
      border-radius: 11px;
      box-shadow: none;
      height: 45px;
      letter-spacing: 2.6px;
      border: 1px solid $color-new-purple;
      &:hover {
        background-color: $color-white;
        color: $color-new-purple;
      }
    }
  }

  &__already-have-account-container {
    text-align: center;
    margin-top: 20px;
    font-family: 'area-normal', Roboto, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
    text-transform: capitalize;
  }

  &__bottom-subtext {
    font-size: 10px;
    font-family: 'area-normal', Roboto, sans-serif;
    font-weight: 400;
    margin-top: 12px;
    color: #3c3c3c;
    text-align: center;
  }
}
.sign-up-text-field.MuiFormLabel-filled {
  padding: 0;
}

.sign-in__container,
.forgot-pass__container,
.change-password__container {
  margin-top: -550px;
}
.page-title-container {
  max-width: 353px;
}

.big-logo-bottom-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.big-logo-bottom {
  background-image: url('@app/imageAssets/homePage/Full_Big_S_logo.svg');
  width: 206px;
  height: 122px;
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-top: 178px;
  // position: fixed;
  // bottom: 0;
}

.sign-in-sign-up {
  .promotion-banner {
    position: relative;
    width: 100%;
    max-width: 472px;
    background: $color-white;
    border-radius: 32px;
    border: 2px solid transparent;
    z-index: 2;
    margin-bottom: 50px;
    display: none;

    &:before {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      z-index: -1;
      border-radius: inherit;
      background: linear-gradient(to bottom, #0043ff 15%, #ea0449 85%);
    }

    &__content {
      display: flex;
      border-radius: 30px;
      background: $color-white;
      width: 100%;
      overflow: hidden;
    }

    &__banner-image {
      // slight change on width based on image used
      width: 102%;
    }

    // left right links - see GDWC image
    // &__link {
    //   width: 50%;
    //   height: 50%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    // }
    // &__link.right_link {
    //   left: 50%;
    // }
  }
}

// sm screen sizes 600px plus
@media screen and (min-width: 600px) {
  .sign-up-pages__background-icon {
    display: inline-block;
  }
}

// lg screen sizes 1280px plus
@media screen and (min-width: 1280px) {
}

.mui.card-container-elevation {
  @include elevation-level-16;
}
