@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.single-selector {
  @include textfield-override-dense;
  @include textfield-override-dense-outlined-transition;

  // not using legend > span mixin here because it adds extra height to selector
  // @include textfield-override-no-label;

  // legend css class mui injects has a different style from that of the now textfield/input
  &.no-label {
    legend {
      width: 0;
    }
  }
}
