@mixin flex-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-left-align {
  display: flex;
  align-items: center;
  justify-content: left;
}
