@mixin width-100-margin-0 {
  margin: 0;
  width: 100%;
}

// mui TextField
@mixin textfield-override-no-label {
  &.no-label {
    legend > span {
      display: none;
    }
  }
}

@mixin textfield-override-dense {
  &.MuiFormControl-marginDense {
    input,
    select {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
      height: 1.1876em;
    }
  }
}

@mixin textfield-override-dense-outlined-transition {
  &.MuiFormControl-marginDense {
    .MuiInputLabel-outlined {
      // .MuiInputLabel-marginDense {
      transform: translate(14px, 12px) scale(1);
      height: 1.1876em;
      line-height: 1.1876em;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
}
