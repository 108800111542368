$modal-vm: 160px;
$modal-vm-mobile: 80px;
$modal-padding: 48px; // 2 x 24px

@mixin modal-style {
  padding: 0; // override 4px set on atom modal
  width: calc(100% - #{$modal-padding});
  max-height: calc(100% - #{$modal-vm-mobile});
  overflow-y: auto;

  @media screen and (min-width: 600px) {
    width: calc(100% - 160px);
    max-width: 600px;
    max-height: calc(100% - #{$modal-vm});
  }

  @media screen and (min-width: 960px) {
    max-width: none;
    width: 600px;
  }
}

@mixin modal-close-button {
  .atom-component__close-button {
    padding: 8px;
    height: 40px; // 24px + 8px * 2 >> icon height + vertical padding
    transition: none;

    &:hover {
      border-radius: 0 4px 0 4px;
    }
  }
}

@mixin modal-section-scroll($otherSectionHeightMobile, $otherSectionHeight) {
  max-height: calc(100vh - #{$modal-vm-mobile + $modal-padding + $otherSectionHeightMobile});
  overflow-y: auto;

  @media screen and (min-width: 600px) {
    max-height: calc(100vh - #{$modal-vm + $modal-padding + $otherSectionHeight});
  }
}
