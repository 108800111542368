/* SINGLE SELECTOR */

.single-selector-menuitem {
  height: auto !important;
}

.show-hide-label {
  position: absolute;
  right: 0px;
  background: transparent;
}

.error-icon {
  margin: 0px 0px -55px 0px;
  width: 15px;
  height: 15px;
}
