// primary
$color-brand-blue: #4890e8; /// Blue 600 - Use this for text links and selected and active states
$color-nav-blue: #c1e1fd; /// Blue 100 - Nav Icons
$color-purple: #6e7be8; /// Versus violet
$color-pink: #cc48e8; /// Powder pink
$color-white: #ffffff; /// For text on dark backgrounds like nav bars

// gray
$color-gray-900-primary-text: #2e323a; /// The primary text color
$color-gray-700-highlight: #515967; /// The color for the hover state of the main sidebar buttons
$color-gray-600-secondary-text: #636d7e;
$color-gray-500-secondary-text: #717b8f; /// This is our secondary text color.  Use this for subtext to deemphasize related content.
$color-gray-400-content: #868fa0;
$color-gray-300-icons: #9ca3b1; /// This is the color for icons
$color-gray-200-disabled-state: #b8bdc7; /// This is the color for the disabled state of buttons
$color-gray-100-borders: #d4d7dd; /// For borders and line separations
$color-gray-50-table-header-site-bkgd: #eeeff0; /// Primary site background color and for background of table headers
$color-gray-25-content-background: #f7f7f8; /// Background of cards to distinguish them from the sites background
$color-gray-tab-disabled-state: #dfe1e5;
$color-gray-arrow-disabled-state: #d7d7d7; /// Color of disabled forward/back arrows in CMS content footers
$color-blue-gray: #c7ced4;
$color-blue-gray-500: #a6b1bb;
$color-blue-gray-600: #7b8b9a;
$color-blue-gray-700: #66788a;

$color-md-gray-500: #9e9e9e;
$color-gray-row-hover: #f6f6fb;

// semantic colors
$color-red: #dd6952; /// Red 400 - For error states and destructive actions
$color-red-border: #f8d1d1; /// Red 100 - border for error banners
$color-red-background: #fef6f6; /// Red 25 - text button hover and red backgrounds
$color-red-hover: #d15839; // Red 600 - For error button hover dark state
$color-red-dark: #a63a29; /// More emphasis on errors
$color-mui-error: #f44336;
$color-green: #7db534; /// For success or validated states
$color-green-dark: #4c8500; /// More emphasis on success
$color-green-light: #b0d779; /// Green 300
$color-green-25: #f8fbf3;
$color-green-50: #f2f8e8;
$color-green-100: #ddeec5;
$color-green-600: #43a047;
$color-blue-hover-dark: #3c6dc2; /// Blue 800 - Dark blue hover
$color-blue-hover-light: #f5fbfe; /// Blue 25 - Light blue hover - white background button hover
$color-info-icon: #77bcf9; /// Blue 300 - Color for icons on info banners
$color-info-border: $color-nav-blue; /// Blue 100 - Color for border for info banners
$color-info-background: #f1f8fe; /// Blue 50 - Background color for info banners
$color-warn-icon: #f7a93d; /// Orange 400 - Color for icons on warn banners
$color-warn-background: #fef9f1; /// Orange 25 - Background color for warn banners
$color-orange-dark-banner: #fad59e;
$color-orange-50: #fdf3e2;
$color-orange-100: #fce0b7; /// Orange 100 - Color for border for warn banners and light banner
$color-orange-500: #ff9800;

// charts and graphs
$color-first: #7eb1ee; /// For the first kind of graph data
$color-first-estimated: #c0dfff; /// For estimated states of the first kind of graph data
$color-second: #eebc7e; /// Second data
$color-second-estimated: #ffe8bb;
$color-third: #bc7eee; /// Third data
$color-third-estimated: #eabcff;
$color-fourth: #7ee9ee; /// Fourth data
$color-fourth-estimated: #c2ffff;
$color-fifth: #847eee; /// Fifth data
$color-fifth-estimated: #c5baff;
$color-sixth: #ee7eb1; /// Sixth data
$color-sixth-estimated: #ffbedf;

// external colors
$color-nfl-blue-dark: #003399;
$color-nfl-blue-medium: #0066cc;
$color-nfl-blue-light: #0099cc;
$color-nfl-blue-text: #9eccf6;
$color-nfl-white-text: #ffffff;
$color-nfl-light-text: #f5f5f5;
$color-nfl-dark-background: #161616;
$color-ufc-red: #d20a0a;

// typography
$standard-regular-weight: 400;
$standard-medium-weight: 500;
$standard-heavy-weight: 600;
$standard-extra-heavy-weight: 700;

// spacing
$navbar-height: 64px;

// sdk
$color-gray-sdk-entry-point-title: #535353; /// Gray used as the Entry Point Title in the SDK

// New branding colors
$color-new-purple: #3e00cd;
$color-new-blue: #0043ff;
$color-new-red: #ea0449;
$color-gradient-purple: #7922a1;
$color-new-grey: #f9f7f7;

// exported variables are camelize of the scss variable
:export {
  // primary
  colorBrandBlue: $color-brand-blue;
  colorPurple: $color-purple;
  colorPink: $color-pink;
  colorWhite: $color-white;
  // gray
  colorGray900PrimaryText: $color-gray-900-primary-text;
  colorGray700Highlight: $color-gray-700-highlight;
  colorGray600SecondaryText: $color-gray-600-secondary-text;
  colorGray500SecondaryText: $color-gray-500-secondary-text;
  colorGray400Content: $color-gray-400-content;
  colorGray300Icons: $color-gray-300-icons;
  colorGray200DisabledState: $color-gray-200-disabled-state;
  colorGray100Borders: $color-gray-100-borders;
  colorGray50Background: $color-gray-50-table-header-site-bkgd;
  colorGray25ContentBackground: $color-gray-25-content-background;
  colorGrayTabDisabledState: $color-gray-tab-disabled-state;
  colorGrayArrowDisabledState: $color-gray-arrow-disabled-state;
  colorBlueGray: $color-blue-gray;
  colorBlueGray500: $color-blue-gray-500;
  colorBlueGray600: $color-blue-gray-600;
  colorBlueGray700: $color-blue-gray-700;
  colorMdGray500: $color-md-gray-500;
  colorGrayRowHover: $color-gray-row-hover;
  // semantics
  colorRed: $color-red;
  colorRedBorder: $color-red-border;
  colorRedBackground: $color-red-background;
  colorRedHover: $color-red-hover;
  colorRedDark: $color-red-dark;
  colorGreen: $color-green;
  colorGreenDark: $color-green-dark;
  colorGreenLight: $color-green-light;
  colorGreen25: $color-green-25;
  colorGreen50: $color-green-50;
  colorGreen100: $color-green-100;
  colorGreen600: $color-green-600;
  colorBlueHoverDark: $color-blue-hover-dark;
  colorBlueHoverLight: $color-blue-hover-light;
  colorInfoIcon: $color-info-icon;
  colorInfoBorder: $color-info-border;
  colorInfoBackground: $color-info-background;
  colorWarnIcon: $color-warn-icon;
  colorWarnBackground: $color-warn-background;
  colorOrangeDarkBanner: $color-orange-dark-banner;
  colorOrange50: $color-orange-50;
  colorOrange100: $color-orange-100;
  colorOrange500: $color-orange-500;
  // charts and graphs
  colorFirst: $color-first;
  colorSecond: $color-second;
  colorThird: $color-third;
  colorFourth: $color-fourth;
  colorFifth: $color-fifth;
  colorSixth: $color-sixth;
  colorFirstEstimated: $color-first-estimated;
  colorSecondEstimated: $color-second-estimated;
  colorThirdEstimated: $color-third-estimated;
  colorFourthEstimated: $color-fourth-estimated;
  colorFifthEstimated: $color-fifth-estimated;
  colorSixthEstimated: $color-sixth-estimated;

  // external colors
  colorNflDark: $color-nfl-blue-dark;
  colorNflMed: $color-nfl-blue-medium;
  colorNflLight: $color-nfl-blue-light;
  colorNflBlueText: $color-nfl-blue-text;
  colorNflText: $color-nfl-white-text;
  colorNflLightTest: $color-nfl-light-text;
  colorNflBackground: $color-nfl-dark-background;

  navBarHeight: $navbar-height;

  // sdk
  colorGraySdkEntryPointTitle: $color-gray-sdk-entry-point-title;
}
