@import '../../styles/core/variables.scss';
@import '../../styles/mixins.scss';

.atom-component {
  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    @include link-remove-text-decoration;

    &-title {
      line-height: 21px;
    }

    &-icon {
      @include flex-center-align;

      svg {
        height: 16px;
        width: auto;
        margin-left: 4px;
      }
    }
  }
}

a.atom-component__link {
  color: $color-brand-blue !important;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    color: $color-blue-hover-dark !important;
  }
}
