@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.atom-component__table-of-contents {
  ul {
    position: sticky;
    top: 16px;

    @at-root .log-out & {
      top: calc(#{$navbar-height} + 16px);
    }
  }

  li {
    list-style-type: none;
    padding: 12px 24px;

    &.is-current {
      background-color: $color-info-background;

      a {
        color: $color-brand-blue;
        font-weight: $standard-medium-weight;
      }
    }
    a {
      @include normal-input-text;
      color: $color-gray-900-primary-text;
    }
  }
}
