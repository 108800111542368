@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.password-checklist {
  &__container {
    display: flex;
    align-items: center;
    @include subtext;
    font-weight: $standard-regular-weight;

    &.passing {
      color: $color-green;
    }

    &.pending {
      color: $color-gray-500-secondary-text;
    }

    &.failed {
      color: $color-red;
    }
  }

  &__image {
    width: 16px;
    margin-right: 8px;
  }
}
