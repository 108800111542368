@import '@app/core/atoms.scss';
@import '@app/core/variables.scss';

.atom-component__table-header {
  background-color: transparent;

  &-shaded-mode {
    background-color: $color-gray-25-content-background;
  }

  .atom-component__table-header-cell {
    font-size: 16px;
    font-family: Roboto;
    color: $color-gray-900-primary-text;
    padding-left: 0px;
    padding-bottom: 8px;
  }
}
