@import '@app/core/variables.scss';
@import '@app/core/mixins.scss';

.atom-component__copy-to-clipboard-container {
  @include flex-center-align;
  display: inline-flex;
  cursor: pointer;
}

.atom-component__copy-to-clipboard-icon {
  height: 16px !important;
  width: 16px !important;
  color: $color-gray-500-secondary-text !important;
}
