@import '@app/core/variables.scss';

$progress-bar-border-radius: 4px;

.atom-component__progress-bar {
  width: 100%;
  margin-top: 16px;
  border-radius: $progress-bar-border-radius;

  &.background {
    background-color: $color-gray-100-borders; // has to be specific selector
  }

  &.small {
    height: 8px;
  }

  &.large {
    height: 24px;
  }

  > .MuiLinearProgress-bar {
    border-top-right-radius: $progress-bar-border-radius;
    border-bottom-right-radius: $progress-bar-border-radius;
    background-image: linear-gradient(to right, $color-brand-blue, $color-green);
    // has to reverse position because how material linear progress is implemented
    background-position: right;
    background-size: var(--p), 100%;
  }

  &.error > div {
    background-image: linear-gradient(to left, $color-red, $color-red-dark);
  }
}
