@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.desktop-view-container {
  .game-icon-step-container {
    padding-bottom: 4px;
    border-right: 4px solid white; // to add some gap with the svg line
    z-index: 1; // this is to render the game over the svg line
  }

  .gift-icon-step-container {
    padding-bottom: 38px;
    z-index: 1; // this is to render the gift over the svg line

    .icon-gift {
      margin-top: -12px;
    }
  }

  .step-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1; // this is to render the gift over the svg line

    &.bottom {
      flex-direction: column-reverse;
    }
  }

  .step-copy {
    position: absolute;
    font-size: 14px;
    font-weight: $standard-medium-weight;
    white-space: nowrap;

    &.top {
      margin-top: -18px;
    }

    &.bottom {
      margin-bottom: -18px;
    }
  }

  .step-copy > p,
  .step-copy > div > p {
    font-size: 14px;
    font-weight: $standard-medium-weight;

    &.pending {
      color: $color-gray-200-disabled-state;
    }
  }

  .step-copy > div > p {
    &.pending {
      color: $color-gray-200-disabled-state;
    }
  }

  .step-copy > div {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-content: space-between;

    // sort of a work-around here to get the Fair play check mark to center the
    // Fair play text along just to text instead of centering around the text & container
    > p {
      margin-left: inherit;
      margin-right: inherit;
      padding-left: 18px;
    }

    > img {
      width: 16px;
      height: 16px;
      padding-bottom: 4px;
      object-fit: contain;
    }
  }
}
