@import '@app/core/atoms.scss';
@import '@app/core/mixins.scss';

.best-practice-card {
  &__container {
    @include elevation-level-1;
    @include elevation-transition;
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    padding: 16px;
    border-radius: 4px;
    cursor: pointer;
    height: 116px;
    color: $color-gray-900-primary-text;

    &:hover {
      @include elevation-level-8;
    }
  }

  &__content-section {
    display: flex;
  }

  &__icon {
    width: 55px;
    height: 55px;
    border-radius: 13px;
    margin-right: 8px;
    flex-shrink: 0;
  }

  &__game-genre {
    @include normal-input-text;
    font-weight: $standard-medium-weight;
    margin-bottom: 8px;
  }

  &__game-name {
    @include normal-input-text;
    font-weight: $standard-medium-weight;
  }

  &__game-description {
    @include subtext;
    font-weight: $standard-regular-weight;
  }
}
