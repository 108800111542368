.material-ui-file-upload-input {
  display: flex;
}

.material-ui-file-upload-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 14px;
  font-family: Roboto;
  line-height: 1;
  flex-shrink: 0;
}

.material-ui-file-upload-file-name-container {
  display: flex;
  align-items: center;
}

.material-ui-file-upload-file-name {
  margin: auto;
}

//material UI already sets it to inline-flex
.material-ui-file-upload-center {
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .material-ui-file-upload-center {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
