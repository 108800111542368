@import '@app/core/mixins.scss';
@import '@app/core/variables.scss';

.common-component__analytics-summary-card-container {
  text-align: center;
  padding: 24px;
  margin-top: 24px;

  &.box-shadow {
    @include elevation-level-1;
  }

  &-grid-component {
    margin-bottom: -32px;
    width: auto;
  }
}
