@import '@app/core/variables.scss';

// only styles needed for react markdown defined here
.revenue-model-notification__dialog {
  table,
  tr,
  td,
  th {
    border: 1px solid $color-gray-900-primary-text;
  }

  table {
    margin-bottom: 16px;
  }

  th,
  td {
    padding: 4px 8px;
    text-align: center;
    line-height: 21px;
  }

  ul {
    margin-top: -8px;
  }
}
